import React from 'react';
import styles from '../css/contact.module.css';

const Contact = () => {
  return (
    <section className={styles.contact}>
      <div className={styles.center}>
        <form
          action='https://formspree.io/xeqejyvb'
          method='POST'
          className={styles.form}
        >
          <div>
            <label htmlFor='name'>name</label>

            <input
              type='text'
              name='name'
              id='name'
              className={styles.formControl}
              placeholder='name'
            />
          </div>
          <div>
            <label htmlFor='email'>email</label>

            <input
              type='email'
              name='email'
              id='email'
              className={styles.formControl}
              placeholder='email@email.com'
            />
          </div>
          <div>
            <label htmlFor='message'>message</label>

            <textarea
              className={styles.formControl}
              name='message'
              id='message'
              rows='10'
              placeholder='message'
            ></textarea>
          </div>
          <div>
            <input type='submit' value='submit' className={styles.submit} />
          </div>
        </form>
      </div>
    </section>
  );
};

export default Contact;
